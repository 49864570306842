import { useDispatch, useSelector } from "react-redux";
import { parse } from "query-string";
import {
  updateMapImage,
  updateZipCodeValue,
  updateZipFilterData,
  updateIsZipSubmitted
} from "../store/slices/zipInfo";
import { checkPostalCode, recordBlockedPostalCode } from "../services/api";
import { INSIGHTS_TYPE } from "../templates/strings";
import { updateRecordActionState } from "../store/slices/funnelInfo";
import { countryMapHash } from "../components/ZipCodeForm/mapImageHash";
export const useZipcodeAction = () => {
  const dispatch = useDispatch();
  const { zipInfo, funnelInfo } = useSelector((state) => state);
  const { funnel } = funnelInfo;
  const handleUpdateZip = ({ value }) =>
    dispatch(updateZipCodeValue(value.slice(0, 5)));

  const handleZipcodeSubmit = () => {
    const nextInsight = funnel.textElements.enablePhoneWithTwoSteps
      ? INSIGHTS_TYPE.LEAD_STEP_ONE
      : INSIGHTS_TYPE.LEAD;
    dispatch(updateIsZipSubmitted(!zipInfo.isSubmitted));
    dispatch(
      updateRecordActionState({
        insightRefId: INSIGHTS_TYPE.ZIP,
        insightType: INSIGHTS_TYPE.ZIP,
        nextInsightRefId: nextInsight,
        nextInsightType: nextInsight
      })
    );
    dispatch(updateZipCodeValue(zipInfo.value));
  };
  const handleCheckZipcode = async () => {
    try {
      const response = await checkPostalCode(funnel.id, zipInfo.value);

      try {
        if (!response.shouldCreate) {
          const searchParams = parse(window.location.search);
          const fbClId = searchParams.fbclid;
          const dynamicSource = searchParams.source?.toLowerCase();
          const testMode = searchParams.testMode === "true" ? true : false;
          const leadPayload = {
            clickId: fbClId,
            testMode,
            sourceUrl: window.location.href,
            lead: {
              funnelId: funnel._id,
              leadDetails: {
                leadZip: zipInfo.value
              },
              adDetails: {
                campaign_id: searchParams.am_campaign_id,
                campaign_name: searchParams.am_campaign_name,
                adset_id: searchParams.am_adset_id,
                adset_name: searchParams.am_adset_name,
                ad_id: searchParams.am_ad_id,
                ad_name: searchParams.am_ad_name
              },
              responses: funnelInfo.qResponses,
              source: dynamicSource
            }
          };
          await recordBlockedPostalCode(leadPayload);
        }
      } catch (e) {
        console.log(e);
      }

      dispatch(updateZipFilterData(response));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleMapImage = ({ country }) =>
    dispatch(updateMapImage(countryMapHash[country]));

  return {
    handleMapImage,
    handleUpdateZip,
    handleCheckZipcode,
    handleZipcodeSubmit
  };
};
